/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * interstate-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9ae809
 *   - http://typekit.com/eulas/00000000000000003b9ae80f
 * neo-sans:
 *   - http://typekit.com/eulas/00000000000000007735bb57
 *   - http://typekit.com/eulas/00000000000000007735bb45
 *   - http://typekit.com/eulas/00000000000000007735bb40
 *   - http://typekit.com/eulas/00000000000000007735bb5f
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-03-18 20:36:10 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=pnz0ocf&ht=tk&f=31162.31168.39475.39476.39477.39482&a=33801418&app=typekit&e=css");

@font-face {
  font-family: "interstate-condensed";
  src: url("https://use.typekit.net/af/e2f97c/00000000000000003b9ae809/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e2f97c/00000000000000003b9ae809/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e2f97c/00000000000000003b9ae809/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "interstate-condensed";
  src: url("https://use.typekit.net/af/4cf1d3/00000000000000003b9ae80f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4cf1d3/00000000000000003b9ae80f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4cf1d3/00000000000000003b9ae80f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "neo-sans";
  src: url("https://use.typekit.net/af/b07eb4/00000000000000007735bb57/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b07eb4/00000000000000007735bb57/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b07eb4/00000000000000007735bb57/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "neo-sans";
  src: url("https://use.typekit.net/af/49bd4e/00000000000000007735bb45/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/49bd4e/00000000000000007735bb45/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/49bd4e/00000000000000007735bb45/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "neo-sans";
  src: url("https://use.typekit.net/af/cc16b7/00000000000000007735bb40/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/cc16b7/00000000000000007735bb40/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/cc16b7/00000000000000007735bb40/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "neo-sans";
  src: url("https://use.typekit.net/af/b34fab/00000000000000007735bb5f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b34fab/00000000000000007735bb5f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b34fab/00000000000000007735bb5f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

/*
 *
 * GLOBAL STYLES
 *
 */

body {
  margin: 0;
  font-family: "neo-sans", "Helvetica Neue", sans-serif;
}

main {
  overflow: hidden;
}

.bubble-info {
  align-items: center;
  display: flex;
  position: absolute;
}

@media screen and (max-width: 1000px) {
  .bubble-info {
    bottom: unset !important;
    justify-content: center;
    left: unset !important;
    padding-left: 20px;
    position: relative !important;
    right: unset !important;
    top: unset !important;
  }
}

.bubble-info h2 {
  margin: 0;
}

.bubble-info > div h2 {
  font-family: "interstate-condensed", "Helvetica Neue", sans-serif;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 29px;
}

@media screen and (max-width: 1000px) {
  .bubble-info > div h2 {
    width: 100%;
  }
}

.bubble-info > div {
  max-width: 125px;
}

@media screen and (max-width: 1460px) {
  .bubble-info > div {
    margin: 0;
  }
}

@media screen and (min-width: 1461px) {
  .bubble-info > div {
    margin: 0 25px;
  }
}

.pagination-ul {
  display: flex;
  position: absolute;
  bottom: 30px;
  padding: 0;
  margin: 0;
  left: 120px;
  list-style: none;
  width: calc(100% - 120px);
}

.pagination-ul li {
  align-items: center;
  display: flex;
  font-family: "interstate-condensed", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 900;
  flex-direction: column;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-right: 50px;
}

.pagination-ul li:last-of-type {
  margin-right: 0;
}

.pagination-ul li::before {
  background-color: #331c54;
  border-radius: 50%;
  content: "";
  display: block;
  height: 12px;
  margin-bottom: 15px;
  width: 12px;
}

.pagination-ul .slick-active a {
  color: #50c9b5;
  text-decoration: none;
}

.pagination-ul .slick-active::before {
  background-color: #50c9b5;
}

.pagination-ul a {
  color: #331c54;
  text-decoration: none;
}

.pagination-ul li a {
  color: #331c54;
  cursor: pointer;
  text-decoration: none;
}

.pagination-ul li a,
.pagination-ul li::before {
  transition: all 0.25s ease-in-out;
}

.disabled {
  pointer-events: none;
  filter: blur(7px);
  opacity: 0.36;
}

.pagination-ul .disabled a {
  color: #5d6061;
}

.pagination-ul .disabled::before {
  background-color: #5d6061;
}

.timeline-purple-modal {
  background: #331c54;
  height: max-content;
  position: absolute;
  width: 100vw;
  z-index: 999;
  top: 0;
  left: 0;
}

.inner-purple-modal {
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 0 160px;
  overflow-y: auto;
  width: auto;
}

.modal-content-flex {
  display: flex;
  margin-top: 30px;
}

.modal-content-flex iframe {
  height: auto;
  margin-bottom: 30px;
  margin-right: 40px;
  width: 419px;
}

.modal-content-flex p {
  padding-left: 0 !important;
  width: 50%;
  margin: 0;
}

.img-copy-modal {
  display: flex;
  margin-top: 30px;
}

.img-copy-modal img {
  height: 300px;
}

.last-img-copy-modal img {
  height: 430px;
}

.img-copy-modal p {
  padding-right: 40px !important;
  margin-top: 0;
}

.modal-hero,
.second-modal-hero {
  padding-top: 50px;
  padding-left: 150px;
}

.modal-hero img {
  width: 100%;
}

.modal-content .caption {
  font-size: 12px !important;
  padding: 0;
  padding-top: 0;
  padding-right: 0 !important;
  margin: 0;
  line-height: 20px !important;
}

.modal-content .first-caption {
  padding-top: 0;
}

.story4-caption {
  padding-left: 150px !important;
}

.story7-caption {
  margin-bottom: 80px !important;
}

.intro-text {
  color: #ffffff !important;
  font-size: 22px !important;
  letter-spacing: 0 !important;
  line-height: 38px !important;
  margin: 0;
  padding: 25px 150px;
  position: relative;
}

.intro-text:after {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  left: 0;
  width: 100%;
}

.inner-purple-modal h3 {
  color: #ffffff;
  font-family: "interstate-condensed";
  font-size: 56px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 67px;
  margin-bottom: 0;
  padding: 0 150px;
}

.close-icon {
  cursor: pointer;
  height: 70px;
  position: absolute;
  right: 40px;
  top: 40px;
  width: 70px;
}

.modal-content p {
  color: #ffffff !important;
  font-size: 18px !important;
  letter-spacing: 0 !important;
  line-height: 28px !important;
  padding: 0 150px;
}

.modal-content .caption-second {
  padding: 0 150px;
  position: absolute;
  bottom: 8px;
}

.modal-content a,
.modal-calloutbox a,
.intro-text a {
  color: #50c9b5;
  font-weight: 900;
}

.modal-content a:hover {
  color: #d1c7dc;
}

.modal-content .quote {
  color: #ffffff !important;
  font-size: 30px !important;
  letter-spacing: 0 !important;
  line-height: 44px !important;
  border-left: 8px solid #50c9b5;
  padding-left: 70px;
}

.modal-calloutbox {
  border-radius: 12px;
  background-color: #f3f3f3;
  padding: 70px;
  margin-top: 50px;
  margin-left: 200px;
}

.story9-calloutbox p {
  font-weight: bold;
}

.story9-calloutbox p,
.story9-calloutbox li {
  font-size: 18px;
}

.story9-calloutbox p:not(:first-of-type) {
  margin-top: 40px;
}

.story9-calloutbox ul:first-of-type {
  padding-left: 30px !important;
}

.story9-calloutbox ul:first-of-type {
  font-weight: bold;
}

.second-modal-calloutbox {
  margin-left: 150px;
}

.modal-calloutbox p {
  margin-top: 0;
  line-height: 30px;
}

.modal-calloutbox {
  color: #5d6061;
}

.modal-calloutbox h5 {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.modal-calloutbox ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.modal-calloutbox li {
  margin-bottom: 20px;
}

.modal-calloutbox li:last-of-type {
  margin-bottom: 0;
}

.modal-calloutbox li a {
  color: #50c9b5;
  font-weight: 900;
  font-size: 18px;
}

.modal-calloutbox li a:hover {
  color: #331c54;
  transition: color linear 0.5s;
}

.timeline-grey-modal {
  box-shadow: -8px 3px 25px 1px rgb(0 0 0 / 25%);
  box-sizing: border-box;
  min-height: 239px;
  width: 407px;
  background-color: #f3f3f3;
  padding: 60px 30px;
  position: absolute;
  z-index: 999;
}

.pointer {
  position: absolute;
  height: 100%;
  top: 0;
  width: auto !important;
}

.timeline-grey-modal h3 {
  color: #331c54;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0;
}

.timeline-grey-modal p {
  color: #5d6061 !important;
  font-size: 22px !important;
  padding: 0;
  margin-bottom: 0;
}

.timeline-grey-modal .close-icon {
  height: 34px;
  right: 30px;
  top: 30px;
  width: 34px;
}

.timeline-placeholder {
  margin-left: 25%;
  margin-top: 15%;
}

.timeline-placeholder p {
  color: #331c54 !important;
  font-size: 36px !important;
  letter-spacing: 0 !important;
  line-height: 50px !important;
}

.modal-intro-text {
  font-size: 22px;
  letter-spacing: 0;
  line-height: 36px;
  padding-bottom: 30px;
}

.pullquote-wrapper {
  position: relative;
}

.float-img {
  float: right;
  padding-left: 20px;
}

.float-img .caption {
  max-width: 420px;
}

/*
 *
 * TIMELINE SPECIFIC STYLES
 *
 */
.timeline-wrapper {
  margin: 150px 0 0 0;
}

@media (min-width: 1000px) and (max-width: 1240px) {
  .timeline-wrapper {
    margin-top: 70px;
  }

  .inner-timeline-content:first-of-type:not(#id-2010
      .inner-timeline-content:first-of-type):not(#id-2017
      .inner-timeline-content:first-of-type)
    > div:first-of-type
    > div:not(.timeline-purple-modal) {
    margin-top: 100px;
  }

  #id-2003 > div:first-of-type .bubble-info {
    top: 10px !important;
    right: 110px !important;
  }

  #id-2003 > div:nth-of-type(2) .bubble-info > a {
    right: -80px !important;
    left: unset !important;
  }

  #id-2003 > div:nth-of-type(2) .bubble-info {
    top: 60px;
    left: -140px !important;
  }

  #id-2003 > div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
    height: 300px !important;
    width: 300px !important;
  }

  #id-2003 > div:nth-of-type(2) .bubble-info {
    left: -160px !important;
  }

  #id-2010
    .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    right: -180px !important;
    left: unset !important;
    top: 0 !important;
  }

  #id-2010
    .inner-timeline-content:last-of-type
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: -30px !important;
  }

  .bubbles-container-first__2020
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 300px !important;
    width: 300px !important;
  }

  .bubbles-container-first__2020
    > div:nth-of-type(2)
    img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
    height: 185px !important;
    width: 185px !important;
  }

  .bubbles-container-second__2020
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
    height: 200px !important;
    width: 200px !important;
  }

  .bubbles-container-second__2020 > div:first-of-type .pointer {
    height: 100% !important;
    transform: unset !important;
    left: 550px !important;
  }

  .bubbles-container-second__2020 > div:first-of-type .timeline-grey-modal {
    bottom: 15px !important;
    top: unset !important;
    left: unset !important;
    right: 370px;
    width: 550px;
  }

  #id-2020 .bubbles-container-first__2020 > div:last-of-type .pointer {
    transform: scaleX(-1);
    left: -100px !important;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: 100px;
    right: 20px;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:last-of-type
    .timeline-grey-modal {
    right: -580px;
    bottom: 25px !important;
  }

  .bubbles-container-second__2020
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    left: 10px !important;
    top: 70px !important;
  }
}

.inner-timeline-content {
  display: flex;
  flex-direction: column;
  width: calc(50% - 50px);
}

.inner-timeline-content:first-of-type {
  margin-right: 100px;
}

.inner-timeline-content
  > div
  > div:not(.timeline-purple-modal):not(.timeline-grey-modal) {
  position: relative;
}

.inner-timeline-content:first-of-type
  > div:first-of-type
  > div:not(.timeline-purple-modal),
.inner-timeline-content:first-of-type
  > div:first-of-type
  img:not(.close-icon):not(.img-copy-modal img) {
  height: 436px;
  width: 436px;
}

.bubbles-container-second__2017:first-of-type
  > div:first-of-type
  img:not(.close-icon):not(.img-copy-modal img),
.bubbles-container-second__2017:first-of-type
  > div:first-of-type
  .timeline-bubbles-container {
  height: 212px;
  width: 212px;
}

.bubbles-container-second__2017:first-of-type .timeline-bubbles-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.story10Div {
  margin: 30px 150px !important;
  margin-right: 0 !important;
}

.story10Div .caption {
  width: 100%;
}

.story10calloutbox {
  margin: 50px 150px !important;
  margin-top: 100px !important;
}

.story10latestimgs:last-of-type .caption {
  padding: 0 150px !important;
}

@media (min-width: 1241px) and (max-height: 950px) {
  #id-2016 > div:first-of-type .timeline-bubbles-container {
    margin-top: 0px !important;
  }
  #id-2016 > div:nth-of-type(2) .timeline-bubbles-wrapper {
    margin-top: 200px !important;
  }
  #id-2016 > div:nth-of-type(3) .timeline-bubbles-wrapper {
    margin-top: 250px !important;
  }

  #id-2018 .bubbles-container-second__2018 > div:last-of-type img {
    height: 250px !important;
    width: 250px !important;
  }

  #id-2018
    .bubbles-container-second__2018
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: 0;
    right: -170px;
  }
}

@media screen and (max-width: 1461px) {
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > div:not(.timeline-purple-modal),
  .inner-timeline-content:first-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 380px;
    width: 380px;
  }

  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img),
  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    .timeline-bubbles-container {
    height: 212px;
    width: 212px;
  }

  #id-2010
    .inner-timeline-content:first-of-type
    > div:first-of-type
    > div:not(.timeline-purple-modal) {
    height: auto;
    width: 300px;
  }
}

@media screen and (max-width: 1241px) {
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > div:not(.timeline-purple-modal),
  .inner-timeline-content:first-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 340px;
    width: 340px;
  }
}

@media screen and (max-width: 1241px) {
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > div:not(.timeline-purple-modal),
  .inner-timeline-content:first-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 320px;
    width: 320px;
  }

  .bubble-2010 > div:first-of-type img {
    height: 280px !important;
    width: 280px !important;
  }

  #id-2010 .bubble-2010 div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
    height: 220px !important;
    width: 220px !important;
  }

  .story10Div {
    margin-left: 0 !important;
  }

  .story10Img img {
    width: 100%;
  }

  .story10latestimgs:last-of-type .caption {
    padding: 0 0 !important;
  }

  .story10calloutbox {
    margin: 100px 0 !important;
  }
}

.inner-timeline-content:first-of-type
  > div:last-of-type
  img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
  height: 181px;
  margin-top: 100px;
  width: 107px;
}

#id-2017
  .inner-timeline-content:first-of-type
  > div:last-of-type
  img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
  height: 421px;
  margin-top: 0;
  width: 421px;
}

#id-2017 .inner-timeline-content:last-of-type > div:first-of-type {
  display: none;
}

#id-2017
  .inner-timeline-content:first-of-type
  > div:first-of-type
  .timeline-grey-modal {
  left: 460px;
  top: 110px;
  width: 500px;
}

#id-2017
  .inner-timeline-content:first-of-type
  > div:first-of-type
  .timeline-grey-modal
  .pointer {
  height: 100%;
  left: -130px;
}

#id-2017
  .inner-timeline-content:last-of-type
  > div:last-of-type
  .timeline-grey-modal {
  bottom: -40px;
  left: unset;
  right: 440px;
  width: 600px;
}

#id-2017
  .inner-timeline-content:last-of-type
  > div:last-of-type
  .timeline-grey-modal
  .pointer {
  transform: unset;
  left: unset;
  right: -150px;
}

#id-2017
  .inner-timeline-content:first-of-type
  > div:last-of-type
  .bubble-info
  > div {
  margin: 0 25px;
}

.inner-timeline-content:not(.bubbles-container-first__2003):last-of-type
  > div:last-of-type
  > div:not(.timeline-grey-modal),
.inner-timeline-content:last-of-type
  > div:last-of-type
  img:not(.close-icon):not(.img-copy-modal img):not(.pointer),
#id-2003 > div:first-of-type img:not(.close-icon):not(.pointer),
#id-2010 .bubble-2010 div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
  height: 287px;
  width: 287px;
}

#id-2010 .bubble-2010-2 div:nth-of-type(2) img:not(.close-icon):not(.pointer),
#id-2012 .bubble-2012 div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
  height: 220px;
  width: 220px;
}

#id-2012 .bubble-2011 div:nth-of-type(1) img:not(.close-icon):not(.pointer),
#id-2012 .bubble-2011 div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
  height: 240px;
  width: 240px;
}

#id-2012 .bubble-2012 > div:nth-of-type(1) img:not(.close-icon):not(.pointer) {
  height: 430px;
  width: 430px;
}

#id-2012 .bubble-2011 div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
  margin-top: 0;
}

.bubble-2012 > div:first-of-type {
  flex-direction: row;
  justify-content: flex-start;
}

.bubble-2012 > div:last-of-type {
  flex-direction: row;
  justify-content: flex-end;
}

.bubble-2012 > div:last-of-type > div {
  width: auto !important;
}

#id-2012
  .bubble-2011
  > div:first-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  right: unset;
  left: 230px;
}

#id-2012
  .bubble-2011
  > div:last-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  top: 25%;
  right: -20px;
}

#id-2012
  .bubble-2012
  > div:first-of-type
  > .timeline-bubbles-wrapper
  .bubble-info
  > div {
  max-width: 200px;
}

#id-2012
  .bubble-2012
  > div:first-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  left: -100px !important;
}

#id-2012
  .bubble-2012
  > div:last-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  top: 10px;
  left: 20px;
}

#id-2012
  .bubble-2012
  > div:first-of-type
  > .timeline-bubbles-wrapper
  .bubble-info
  > div {
  margin: 0;
}

#id-2012 .bubble-2012 > div:first-of-type .timeline-grey-modal {
  width: 500px;
}

/* #id-2012 {
  margin-top: 100px;
} */

.bubble-2010-2 div:nth-of-type(2) {
  margin-left: 100px;
  margin-top: 0;
}

.inner-timeline-content:last-of-type
  > div:first-of-type
  img:not(.close-icon):not(.img-copy-modal img) {
  height: 354px;
  width: 354px;
}

#id-2003 > div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
  height: 437px;
  width: 437px;
}

@media screen and (max-width: 1461px) {
  .inner-timeline-content:last-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 320px;
    width: 320px;
  }
}

@media screen and (max-width: 1241px) {
  .inner-timeline-content:last-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 280px;
    width: 280px;
  }

  #id-2012 {
    margin-top: 0;
  }
}

.inner-timeline-content:first-of-type {
  justify-content: center;
}

.inner-timeline-content:first-of-type
  > div:last-of-type
  .timeline-bubbles-wrapper,
.inner-timeline-content:last-of-type
  > div:first-of-type
  .timeline-bubbles-wrapper,
.inner-timeline-content:last-of-type
  > div:first-of-type
  .timeline-bubbles-wrapper {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1241px) {
  .inner-timeline-content:last-of-type:not(#id-2010
      .inner-timeline-content:last-of-type)
    > div:first-of-type
    .timeline-bubbles-wrapper:not(.bubbles-container-first__2003
      .timeline-bubbles-wrapper) {
    margin-bottom: 200px;
  }
}

.inner-timeline-content:first-of-type > div:last-of-type {
  flex-direction: row;
}

.inner-timeline-content:first-of-type
  > div:last-of-type
  .timeline-bubbles-wrapper {
  width: 107px;
}

.inner-timeline-content > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.inner-timeline-content:last-of-type > div:last-of-type {
  height: 100%;
}

.inner-timeline-content:last-of-type
  > div:first-of-type
  .timeline-bubbles-wrapper
  > h2 {
  bottom: 25px;
  position: absolute;
  transform: translate(-70%, 50%);
}

.inner-timeline-content:first-of-type
  > div:first-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  transform: translate(-50%, -50%);
  top: 30%;
  right: -65%;
}

.bubbles-container-second__2017:first-of-type
  > div:first-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  transform: translate(-50%, -50%);
  top: 50%;
  right: -30px;
}

.bubble-2010 > div:last-of-type .bubble-info {
  right: -25px !important;
}

@media screen and (max-width: 1461px) {
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    right: -55%;
  }

  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    transform: translate(-50%, -50%);
    top: 50%;
    right: -30px;
  }
}

@media screen and (max-width: 1241px) {
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    bottom: 10%;
    right: -50%;
  }
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > div {
    margin-left: -40px !important;
    margin-top: 40px;
  }

  .bubble-2010
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > div {
    margin-top: 0 !important;
  }

  .bubble-2010 > div:last-of-type .bubble-info {
    right: 0px !important;
  }

  .pagination-ul li {
    margin-right: 30px;
  }
}

@media screen and (max-width: 1241px) {
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > a {
    left: -65px;
    top: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > a {
    left: 35px;
    top: 100px;
  }
}

@media screen and (max-width: 600px) {
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > a {
    top: 55%;
    left: 0px;
  }

  #id-2010
    .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > a {
    top: 55%;
    left: 0px;
  }
}

.inner-timeline-content:first-of-type
  > div:last-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  top: 50%;
  right: -35px;
  transform: translate(-50%, -50%);
}

.bubbles-container-second__2017:first-of-type
  > div:last-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  top: 70px;
  right: -250px;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1000px) {
  .inner-timeline-content:first-of-type
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    position: absolute !important;
  }
}

.inner-timeline-content:last-of-type
  > div:last-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  top: 50px;
}

.inner-timeline-content:last-of-type
  > div:first-of-type
  > .timeline-bubbles-wrapper
  .bubble-info
  a {
  order: 2;
}

@media screen and (max-width: 1461px) {
  .inner-timeline-content:last-of-type:not(#id-2010
      .inner-timeline-content:last-of-type)
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    a {
    left: unset;
    right: -40px;
    top: 50%;
  }
}

.inner-timeline-content:last-of-type
  > div:first-of-type
  > .timeline-bubbles-wrapper
  > .bubble-info
  a {
  position: absolute;
  right: 0;
}

.inner-timeline-content:last-of-type:not(#id-2010
    .inner-timeline-content:last-of-type)
  > div:first-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  left: -80px;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1461px) {
  .inner-timeline-content:not(.inner-timeline-content.bubble-2010-2):last-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: 75%;
    left: -35px;
  }

  .bubble-2010-2 > div:first-of-type > .timeline-bubbles-wrapper .bubble-info {
    right: 300px !important;
  }
}

@media screen and (max-width: 1241px) {
  .inner-timeline-content:last-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    left: -50px;
    top: 25%;
  }
}

@media screen and (max-width: 1000px) {
  .inner-timeline-content:last-of-type:not(.bubbles-container-first__2003)
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    left: -35px !important;
  }

  #id-2010
    .inner-timeline-content:last-of-type:not(.bubbles-container-first__2003)
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    left: 20px !important;
  }

  #id-2010 .inner-timeline-content > div {
    align-items: center;
    justify-content: center;
  }

  .bubble-2010 > div:last-of-type {
    margin-top: 80px;
  }

  .bubble-2010 > div:last-of-type a {
    left: 25px;
  }

  #id-2003 > div:nth-of-type(2) .bubble-info > a {
    order: unset !important;
    position: unset;
    margin-top: 30px;
  }

  #id-2003 > div:nth-of-type(2) .bubble-info {
    justify-content: flex-start;
    padding-left: 0;
  }

  .story7-caption {
    margin-bottom: 30px !important;
  }

  .story7two-images > div,
  .story7Div {
    min-height: unset !important;
  }

  .story7video-wrapper > div:first-of-type p {
    padding-right: 0 !important;
  }

  .story7video-wrapper .story7Video {
    height: auto !important;
    margin-top: unset;
    margin-bottom: 70px;
  }

  .story7Video video {
    width: 100%;
  }
}

/*
 *
 * TIMELINE MODALS SPECIFIC STYLES
 *
 */

.inner-timeline-content:last-of-type > div:last-of-type .timeline-grey-modal {
  left: 140px;
  bottom: 30px;
}

.inner-timeline-content:last-of-type
  > div:last-of-type
  .timeline-grey-modal
  .pointer {
  transform: scaleX(-1);
  left: -120px;
}

#id-2010 .bubble-2010 > div:last-of-type .timeline-grey-modal .pointer {
  left: unset;
  right: 405px;
  transform: scaleX(-1);
}

#id-2010 .bubble-2010-2 > div:last-of-type .timeline-grey-modal .pointer {
  left: unset;
  right: -140px;
  transform: unset;
}

#id-2010 .bubble-2010-2 > div:last-of-type .timeline-grey-modal {
  bottom: 60px;
  left: -740px;
  width: 550px;
}

#id-2012 .bubble-2011 > div:first-of-type .timeline-grey-modal {
  width: 550px;
  top: -85px;
  left: 460px;
}

#id-2012 .bubble-2011 > div:first-of-type .timeline-grey-modal .pointer {
  left: -200px;
  height: 100%;
}

#id-2012 .bubble-2011 > div:last-of-type .timeline-grey-modal {
  left: unset;
  right: -600px !important;
  bottom: -100px !important;
}

#id-2012 .bubble-2011 > div:last-of-type .timeline-grey-modal .pointer {
  left: -180px !important;
  transform: scale(-1, 1);
}

#id-2012 .bubble-2012 > div:last-of-type .timeline-grey-modal {
  bottom: -60px;
  left: unset;
  right: 400px;
}

#id-2012 .bubble-2012 > div:last-of-type .timeline-grey-modal .pointer {
  right: -205px;
  left: unset;
  transform: unset;
}

#id-2013 {
  height: 600px;
  padding-left: 90px;
}

#id-2013 > div:first-of-type,
#id-2013 > div:last-of-type {
  width: calc(100% - 400px);
}

#id-2013 > div:nth-of-type(2) {
  width: 400px;
}

#id-2013 > div:first-of-type .timeline-bubbles-container > img,
#id-2013 > div:last-of-type .timeline-bubbles-container > img {
  height: 212px;
  width: 212px;
}

#id-2013 > div:first-of-type .timeline-bubbles-container .bubble-info {
  top: 40px;
  left: 180px;
}

#id-2013 > div:nth-of-type(2) .timeline-bubbles-container .bubble-info {
  top: 0;
  right: -170px;
}

#id-2013 > div:nth-of-type(2) .timeline-bubbles-container .bubble-info > div {
  margin-left: 25px;
}

#id-2013 > div:last-of-type .timeline-bubbles-container .bubble-info {
  top: 30px;
}

#id-2013 > div:last-of-type {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

#id-2013 > div:nth-of-type(2) {
  align-items: center;
  display: flex;
}

#id-2013 > div:nth-of-type(2) .timeline-bubbles-container > img {
  height: 400px;
  width: 400px;
}

#id-2013 > div:first-of-type .timeline-grey-modal {
  left: 450px;
  top: -50px;
}

#id-2013 > div:last-of-type .timeline-grey-modal {
  right: 360px;
  top: 310px;
}

#id-2013 > div:first-of-type .pointer {
  transform: scale(-1, 1);
  left: -145px;
}

#id-2013 > div:last-of-type .pointer {
  right: -155px;
}

.story11quote1 {
  margin: 50px 0;
}

.story11video {
  margin: 50px 0;
  padding-left: 150px;
}

.story11video .caption {
  max-width: 640px;
}

.story11calloutbox {
  margin: 50px 150px;
}

.inner-timeline-content:first-of-type > div:last-of-type .timeline-grey-modal {
  left: -380px;
  bottom: -100px;
}

#id-2010 .bubble-2010 > div:last-of-type .timeline-grey-modal {
  left: 260px;
  bottom: -30px;
}

#id-2010
  .inner-timeline-content:first-of-type
  > div:last-of-type
  .timeline-grey-modal
  p {
  margin-bottom: 5px;
}

#id-2010
  .inner-timeline-content:first-of-type
  > div:last-of-type
  .timeline-grey-modal
  a {
  font-size: 22px !important;
}

.inner-timeline-content:first-of-type > div:last-of-type .pointer {
  right: -150px;
  top: 0;
}

.inner-timeline-content:last-of-type > div:first-of-type .timeline-grey-modal {
  top: -10px;
  left: 130px;
}

.inner-timeline-content:first-of-type > div:first-of-type .pointer {
  transform: scale(-1, 1);
}

/*
 *
 * TIMELINE 2003
 *
 */

#id-2003 > div:first-of-type {
  position: relative;
}

#id-2003 > div:first-of-type img:not(.close-icon):not(.pointer) {
  border-radius: 50%;
}

#id-2003 > div:first-of-type .bubble-info {
  top: 50px;
  right: 0;
}

#id-2003 > div:first-of-type .timeline-grey-modal {
  top: unset;
  left: 390px;
  bottom: 110px;
}

#id-2003 > div:nth-of-type(2) .bubble-info > a {
  order: 2;
}

#id-2003 > div:nth-of-type(2) .bubble-info {
  top: 60px;
  left: -190px;
}

#id-2003 > div:first-of-type .pointer {
  transform: scale(-1, 1);
  left: -155px;
}

.slick-list,
.slick-track,
#id-2003 {
  height: 100%;
}

[data-index="1"] > div {
  height: 100%;
}

.third-modal-hero {
  box-sizing: border-box;
  margin-top: 40px;
  width: 100%;
}

.third-2-img {
  height: 339px;
  padding-left: 150px;
  margin-top: 50px;
  width: 729px;
}

.third-2-img-caption {
  max-width: 729px;
  margin-bottom: 50px !important;
  padding-top: 10px !important;
  padding-left: 150px !important;
}

.story11-third-2-img {
  height: auto;
  width: 700px;
}

.story11-third-2-img-caption {
  max-width: 700px;
  padding-top: 0 !important;
}

.story11two-images {
  padding-left: 230px !important;
}

.story11two-images > div {
  height: auto !important;
  width: auto !important;
}

.story11two-images img {
  height: 554px !important;
  object-fit: cover;
  width: 420px !important;
}

.story11two-images .caption {
  width: 420px;
}

.story11-third-second-container {
  padding-left: 150px;
}

.story11-third-second-container p,
.story11-third-second-container img {
  padding: 0 !important;
}

.story11-third-second-container p {
  margin-top: 0;
}

.story11-third-second-container img {
  height: auto !important;
  width: 420px;
}

.story11-third-second-container .caption {
  width: 420px;
}

.third-modal-hero-wrapper {
  position: relative;
}

.third-modal-hero {
  padding: 0 150px;
}

.story6MainImg {
  width: 70%;
}

.story6MainImg .third-modal-hero {
  padding-right: 0;
}

.fourth-modal-first-quote {
  margin-top: 80px;
}

.third-first-container,
.third-third-container {
  display: flex;
  padding-left: 150px;
  margin-top: 50px;
}

.story7video-wrapper {
  padding-left: 0;
}

.third-first-container > p,
.third-third-container div:first-of-type p {
  padding: 0;
  margin: 0;
  margin-right: 40px;
}

.third-first-container > div {
  height: 230px;
}

.story7video-wrapper {
  margin-bottom: 120px;
}

.story7video-wrapper > div:first-of-type {
  height: auto;
}

.story7video-wrapper > div:first-of-type p {
  padding-right: 40px;
  margin-top: 0;
}

.story7Video {
  height: 314px !important;
  width: 420px;
}

.story7Video video {
  height: 100%;
}

.third-first-container .caption:not(.story10Img .caption) {
  padding-top: 10px !important;
}

.third-second-container {
  margin: 50px 0;
  margin-top: 70px;
  display: flex;
}

.third-second-container img {
  height: 290px;
  padding-left: 80px;
  margin-right: 40px;
}

.story9third-second-container p {
  margin-right: 40px !important;
}

.story15-img-cntr .caption {
  margin-right: 40px !important;
}

.story15-img-cntr p {
  padding-right: 0 !important;
}

.story6img3cntr {
  display: block;
}

.story6img3cntrdiv {
  margin-bottom: 20px;
  float: left;
}

.story6img3cntr img {
  padding-left: 0;
}

.third-third-container img {
  height: 270px;
}

.story9third-third-container img {
  height: 350px;
}

.third-second-container div:first-of-type p {
  padding-left: 80px;
}

.third-second-container.story15-img-cntr p.caption {
  max-width: 580px;
}

.third-second-container div:last-of-type p {
  padding: 0;
  padding-right: 150px;
  margin: 0;
}

.third-second-container div:last-of-type p:first-of-type,
.third-third-container div:first-of-type p:first-of-type {
  margin-bottom: 20px;
}

.third-third-container {
  margin-bottom: 50px;
}

.third-last-quote {
  max-width: 800px;
  margin: 60px 0;
}

.third-modal-last-img {
  height: 387.03px;
  padding-left: 150px;
  margin-top: 50px;
  width: 570px;
}

.sixth-modal-last-img {
  height: auto;
  padding-right: 150px;
  width: unset;
}

.sixth-modal-last-img img {
  width: 100%;
}

.third-modal-last-caption {
  max-width: 570px;
  padding-left: 150px !important;
  padding-top: 10px !important;
}

/*
 *
 * MEDIA QUERY STYLES
 *
 */
@media (max-width: 1000px) {
  .pagination-ul {
    display: none;
  }

  .timeline-bubbles-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .timeline-bubbles-wrapper .bubble-info {
    order: -1;
    transform: unset !important;
    margin-bottom: 25px;
  }

  .timeline-bubbles-wrapper .bubble-info div {
    margin-left: 30px;
  }

  .timeline-bubbles-wrapper .bubble-info a {
    height: 50px;
    width: 50px;
  }

  #id-2001 > div img:not(.close-icon),
  #id-2003 > div img:not(.close-icon) {
    height: 300px !important;
    width: auto !important;
  }

  #id-2003 > div:first-of-type {
    margin-top: 0;
  }

  .timeline-placeholder {
    margin: 0;
    margin-top: 50px;
    padding: 0 15px;
  }

  .timeline-placeholder p {
    font-size: 30px !important;
    margin-top: 0;
  }

  .modal-entire-row-div img,
  .modal-entire-row-div .caption {
    width: 100% !important;
  }

  .bubbles-container-first__2001 > div > .timeline-bubbles-wrapper {
    margin-bottom: 0 !important;
  }
  .inner-timeline-content {
    width: 100%;
  }

  .inner-timeline-content:first-of-type {
    margin-right: 0;
  }

  .inner-timeline-content:not(.bubbles-container-first__2003, #id-2010
      .inner-timeline-content)
    > div {
    margin-bottom: 100px;
    justify-content: center;
  }

  .inner-timeline-content:first-of-type
    > div:last-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    margin-top: 0;
  }

  .inner-timeline-content:last-of-type > div:last-of-type > div,
  .inner-timeline-content:first-of-type
    > div:first-of-type
    > div:not(.timeline-purple-modal) {
    width: auto;
  }

  .inner-timeline-content:last-of-type
    > div:first-of-type
    .timeline-bubbles-wrapper
    > h2 {
    transform: translate(0, 50%);
  }

  .inner-timeline-content:last-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    a {
    order: unset;
  }

  .third-modal-hero {
    padding: 0 !important;
  }

  .third-2-img,
  .third-2-img-caption {
    padding-left: 0 !important;
    height: auto;
    width: 100%;
  }

  .third-first-container,
  .third-second-container,
  .third-third-container {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  .third-second-container img {
    height: auto;
    margin-right: 0;
    width: 100%;
  }

  .third-second-container > div {
    width: 100%;
    margin-bottom: 50px;
  }

  .third-first-container > div,
  .third-third-container img,
  .third-modal-last-img {
    margin-top: 50px;
    height: auto;
    width: 100%;
  }

  .third-first-container iframe {
    width: 100%;
  }

  .third-first-container > p,
  .third-first-container > p,
  .third-third-container div:first-of-type p {
    margin-right: 0;
  }

  .story4-caption {
    padding-left: unset !important;
  }

  #id-2017 .bubbles-container-second__2017 img:not(.close-icon):not(.pointer),
  #id-2017 .bubble-2017-2 img:not(.close-icon):not(.pointer) {
    height: 240px;
    width: 240px;
  }

  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img),
  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    .timeline-bubbles-container {
    margin-top: 40px;
  }

  .bubbles-container-second__2017:first-of-type
    > div:last-of-type
    .timeline-bubbles-container {
    margin-top: 150px;
  }

  .bubbles-container-second__2017:first-of-type
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: -150px !important;
    right: -50px !important;
  }

  .bubbles-container-second__2017:first-of-type
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > a {
    left: -15px;
  }

  .bubbles-container-second__2017:first-of-type
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > div {
    margin: 0;
    max-width: 250px;
  }

  @media screen and (max-width: 1000px) {
    .inner-timeline-content:last-of-type
      > div:first-of-type
      > .timeline-bubbles-wrapper
      .bubble-info
      a {
      left: 55px;
      right: unset;
    }

    .modal-content .caption-second {
      padding: 0;
    }

    .float-img {
      float: unset;
      padding: 0;
      margin-bottom: 25px;
    }

    .float-img img {
      max-width: 100%;
    }

    .two-images {
      display: block !important;
      padding-left: unset !important;
      margin: 50px 0 !important;
    }

    .two-images div:first-of-type {
      height: auto !important;
      min-height: 350px;
      margin-right: 0 !important;
      margin-bottom: 50px;
      width: 100% !important;
    }

    .two-images div:last-of-type {
      height: auto !important;
      min-height: 350px;
      width: 100% !important;
    }

    .story4-float-img > div,
    .story4Div {
      height: auto !important;
      min-height: 350px;
      margin: 50px 0 !important;
      width: 100% !important;
    }

    .third-modal-calloutbox {
      margin: 50px 0 !important;
    }

    .story6MainImg {
      width: 100%;
    }

    .story6quote1 {
      margin-left: 0 !important;
    }

    #id-2013 > div:nth-of-type(2) .timeline-bubbles-container > img,
    #id-2017
      .inner-timeline-content:first-of-type
      > div:last-of-type
      img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
      height: 300px;
      width: 300px;
    }

    .bubble-2017-2 > div:nth-of-type(2) {
      align-items: center;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .inner-timeline-content:last-of-type
      > div:first-of-type
      > .timeline-bubbles-wrapper
      .bubble-info
      a {
      left: 25px;
      right: unset;
    }
  }

  .inner-timeline-content:first-of-type
    > div:last-of-type
    img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
    margin-top: 0;
  }

  .inner-timeline-content:last-of-type
    > div:last-of-type
    > div:not(.timeline-grey-modal) {
    width: auto;
  }

  .timeline-purple-modal {
    margin-bottom: 50px;
  }
  .inner-purple-modal {
    margin: 120px 20px;
    padding: 0;
    width: auto;
    min-height: 1600vh;
  }
  .inner-purple-modal img {
    object-fit: contain;
  }

  .modal-hero,
  .second-modal-hero {
    padding-left: 0;
  }

  .second-modal-hero {
    width: 100%;
  }

  .second-modal-hero > .caption {
    padding-left: 150px;
  }

  .modal-content-flex {
    flex-direction: column;
  }

  .modal-content-flex iframe {
    height: 400px;
    width: 100%;
  }

  .modal-content-flex p {
    margin-top: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  #id-2001 .inner-timeline-content .timeline-bubbles-container > img,
  #id-2003 .timeline-bubbles-wrapper .timeline-bubbles-container > img,
  #id-2005 .timeline-bubbles-wrapper .timeline-bubbles-container > img,
  #id-2006 .timeline-bubbles-wrapper .timeline-bubbles-container > img,
  #id-2008 .timeline-bubbles-wrapper .timeline-bubbles-container > img,
  #id-2010 .timeline-bubbles-wrapper .timeline-bubbles-container > img {
    width: 250px !important;
    height: 250px !important;
  }
}

@media screen and (max-width: 1240px) {
  .timeline-grey-modal {
    right: 0;
    top: 0 !important;
    height: 100%;
    position: fixed;
    overflow: auto;
    width: 100vw;
    z-index: 999;
    left: 0 !important;
  }

  .timeline-grey-modal .pointer {
    display: none;
  }

  .inner-grey-modal {
    margin-top: 35%;
  }

  .timeline-grey-modal h3 {
    margin-bottom: 50px;
  }

  .inner-purple-modal {
    box-sizing: border-box;
    margin: 0;
    padding: 100px 40px;
    overflow-y: unset;
    width: 100%;
  }

  .inner-purple-modal h3,
  .modal-content p {
    padding: unset;
  }

  .inner-purple-modal h3 {
    font-size: 36px;
    line-height: 45px;
  }

  .inner-purple-modal .intro-text {
    padding: 25px 0;
  }

  .close-icon {
    height: 35px;
    width: auto !important;
  }

  .img-copy-modal {
    flex-direction: column;
  }

  .img-copy-modal p:not(.quote) {
    padding: 0 !important;
  }

  .img-copy-modal img {
    margin-top: 25px;
    max-width: 100%;
    height: auto;
    width: auto;
  }

  .modal-content .quote {
    padding-left: 30px;
  }

  .modal-content-flex iframe {
    margin-bottom: 0;
    width: 100%;
  }

  .modal-calloutbox {
    margin-left: 0;
    padding: 40px;
  }

  .third-first-container,
  .third-third-container,
  .third-second-container img,
  .third-second-container div:first-of-type p,
  .third-modal-last-img,
  .third-modal-last-caption {
    padding-left: 0 !important;
  }

  .third-second-container div:last-of-type p {
    padding-right: 0;
  }

  .fourth-modal-first-quote {
    margin: 40px 0;
  }

  .two-images-fourth-modal {
    padding-right: 0 !important;
    margin-bottom: 0 !important;
  }

  .story5-float-img {
    float: unset !important;
    margin: unset !important;
    width: 100% !important;
  }

  .story5Div {
    min-height: auto;
  }

  #id-2012
    .bubble-2012
    > div:nth-of-type(1)
    img:not(.close-icon):not(.pointer) {
    height: 300px;
    width: 300px;
  }

  #id-2012 .bubble-2011 > div:first-of-type .timeline-bubbles-wrapper {
    margin-top: 0 !important;
  }

  #id-2012 .bubble-2012 > div:first-of-type .timeline-bubbles-wrapper {
    margin-bottom: 36px !important;
  }
}

@media (min-width: 1241px) and (max-width: 1440px) {
  .inner-timeline-content:first-of-type
    > div:last-of-type
    .timeline-grey-modal {
    min-height: auto;
    right: -650px;
    left: unset !important;
    bottom: 30px !important;
    width: 500px !important;
  }

  .inner-timeline-content:first-of-type > div:last-of-type .pointer {
    transform: rotate(-180deg);
    right: unset !important;
    left: -135px !important;
    top: 0 !important;
  }

  #id-2003 > div:first-of-type .pointer {
    left: -100px;
  }

  #id-2003 > div:nth-of-type(2) .bubble-info {
    left: -140px;
  }

  .third-first-container > p {
    max-width: 45%;
  }

  .third-first-container > div {
    height: auto;
  }

  .third-first-container > div iframe {
    height: auto;
    min-height: 300px;
    width: 100%;
  }

  #id-2008 > div:nth-of-type(2) .bubble-info > a {
    position: absolute;
    left: -80px;
  }

  #id-2010
    .inner-timeline-content:last-of-type
    > div:last-of-type
    img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
    height: 220px;
    width: 220px;
  }

  #id-2017
    .inner-timeline-content:first-of-type
    > div:first-of-type
    .timeline-grey-modal {
    top: 90px;
    left: 450px;
  }
}

@media screen and (min-width: 1000px) {
  #id-2003 {
    justify-content: space-between;
  }

  #id-2010 .bubble-info > div {
    max-width: 200px;
  }

  #id-2010
    .inner-timeline-content:last-of-type
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: 200px;
    right: -30px;
  }

  #id-2010 .bubble-2010 {
    height: 100%;
  }

  #id-2010
    .bubble-2010-2
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: -10px !important;
    right: 190px !important;
  }

  .inner-timeline-content:last-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    a {
    order: 2;
  }

  #id-2010
    .bubble-2010
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    a {
    order: unset;
  }

  #id-2010
    .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    transform: unset;
    right: -265px;
    top: 15%;
    left: unset;
  }
}

@media screen and (min-width: 1441px) {
  #id-2003 .bubble-info {
    top: 55px;
  }

  #id-2003 > div:first-of-type .pointer {
    left: -100px;
  }

  #id-2010
    .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    transform: unset;
    right: -290px;
    top: 20%;
  }

  .bubbles-container-first__2020 > div:first-of-type .bubble-info {
    right: -250px !important;
    top: 100px !important;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:last-of-type
    .timeline-grey-modal {
    left: unset;
    right: -500px !important;
    bottom: 0 !important;
  }

  #id-2020 .bubbles-container-first__2020 > div:last-of-type .pointer {
    left: -110px !important;
  }
}

@media screen and (min-width: 1750px) {
  .inner-purple-modal {
    max-width: 1750px;
    margin: 50px auto;
  }
}

@media screen and (min-width: 1720px) {
  .last-float-img img {
    height: 350px;
  }

  .last-float-img .caption {
    max-width: 280px;
  }

  .third-first-container > div {
    height: auto;
  }
}

.two-images {
  display: flex;
  padding-left: 150px;
  margin: 80px 0;
}

.two-images-fourth-modal {
  padding-left: 0 !important;
  padding-right: 150px;
}

.two-images div:first-of-type {
  height: 300px;
  margin-right: 40px;
  width: 400px;
}

.two-images-fourth-modal div:first-of-type {
  height: 430px !important;
  width: 550px !important;
}

.two-images div:first-of-type img {
  height: 100%;
  width: 100%;
}

.two-images div:last-of-type {
  height: 390px;
  width: calc(100% - 440px);
}

.story9two-images div:last-of-type {
  height: auto;
}

.two-images-fourth-modal div:last-of-type {
  height: 300px !important;
  width: calc(100% - 590px) !important;
}

.two-images div:last-of-type img {
  height: 100%;
  width: 100%;
}

.story4-float-img > div {
  float: right;
  width: 420px;
  height: 380px;
  margin-bottom: 100px;
  margin-left: 100px;
}

.story4-float-img img {
  height: 100%;
  width: 100%;
}

.story5-float-img {
  float: right;
  height: auto;
  margin-left: 40px;
  margin-bottom: 100px;
  width: 420px;
}

.story6-float-img {
  margin-bottom: 80px;
}

.story5-float-img img {
  height: 100%;
  width: 100%;
}

.story4Div {
  height: 400px;
  margin: 80px 0;
  width: 725px;
}

.story5Div {
  height: 426px;
  width: 875px;
}

.story7Div {
  height: 450px;
}

.story6Div {
  margin: 80px 150px;
  height: 450px;
  width: unset;
}

.quote-story-5 {
  padding-right: 0 !important;
}

.story6quote1 {
  margin: 80px 0;
  margin-left: 150px;
}

.story7quote1 {
  margin-left: 0;
}

.story6quote2 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.story4Div img {
  height: 100%;
  width: 100%;
}

.third-modal-calloutbox {
  margin: 0 150px;
  margin-top: 50px;
}

@media (min-width: 1001px) {
  #id-2003 > div:first-of-type img:not(.close-icon):not(.pointer) {
    height: 200px;
    width: 200px;
  }

  #id-2003 > div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
    height: 300px;
    width: 300px;
  }

  #id-2003 > div:nth-of-type(3) .timeline-bubbles-wrapper {
    position: relative;
  }

  #id-2003 > div:nth-of-type(3) img:not(.close-icon):not(.pointer) {
    height: 200px;
    width: 200px;
  }

  .bubbles-container-first__2003
    > div:first-of-type
    img:not(.close-icon):not(.pointer) {
    height: 200px !important;
    width: 200px !important;
  }

  .bubbles-container-first__2003
    > div:last-of-type
    img:not(.close-icon):not(.pointer) {
    height: 300px !important;
    width: 300px !important;
  }

  .bubbles-container-first__2003 {
    justify-content: space-between;
    width: 40%;
  }

  .bubbles-container-first__2003:last-of-type > div:last-of-type {
    height: auto;
  }

  .bubbles-container-first__2003 .inner-timeline-content > div:first-of-type {
    flex-direction: row;
    justify-content: flex-start;
  }

  #id-2003 > div:first-of-type .bubble-info {
    right: -10px;
  }

  #id-2003 > div:first-of-type .timeline-grey-modal {
    bottom: -20px;
    left: 320px;
  }

  #id-2003 > div:nth-of-type(3) .bubble-info {
    top: 15px;
  }

  #id-2003 > div:nth-of-type(5) .bubble-info {
    top: 0;
    right: -140px;
  }

  .bubbles-container-first__2003 > div:first-of-type .timeline-bubbles-wrapper {
    justify-content: flex-start !important;
  }

  .bubbles-container-first__2003 > div:first-of-type .bubble-info {
    top: 50% !important;
    left: 0 !important;
    height: 35px;
    width: 35px;
  }

  .bubbles-container-first__2003 > div:last-of-type .bubble-info {
    top: 0;
    right: -155px;
  }

  .bubbles-container-first__2003 > div:last-of-type {
    flex-direction: row;
    justify-content: center;
  }

  #id-2005,
  #id-2006,
  #id-2008 {
    justify-content: space-between;
  }

  #id-2005 > div:last-of-type .timeline-bubbles-wrapper,
  #id-2006 > div:last-of-type .timeline-bubbles-wrapper,
  #id-2008 > div:last-of-type .timeline-bubbles-wrapper {
    position: relative;
    margin-top: 100px;
  }

  #id-2005 > div:first-of-type .timeline-bubbles-wrapper,
  #id-2006 > div:first-of-type .timeline-bubbles-wrapper,
  #id-2008 > div:first-of-type .timeline-bubbles-wrapper {
    position: relative;
    margin-top: 160px;
  }

  #id-2005 > div:first-of-type .bubble-info,
  #id-2006 > div:first-of-type .bubble-info,
  #id-2008 > div:first-of-type .bubble-info {
    top: 20px;
    right: 25px;
  }

  #id-2005 > div:nth-of-type(2) .bubble-info > a,
  #id-2006 > div:nth-of-type(2) .bubble-info > a {
    position: unset;
    order: 2;
  }

  #id-2005 > div:nth-of-type(2) .bubble-info > div,
  #id-2006 > div:nth-of-type(2) .bubble-info > div,
  #id-2008 > div:nth-of-type(2) .bubble-info > div {
    margin-right: 0;
  }

  #id-2005 > div:nth-of-type(2) .bubble-info,
  #id-2006 > div:nth-of-type(2) .bubble-info,
  #id-2008 > div:nth-of-type(2) .bubble-info {
    top: 120px;
    left: -170px;
  }

  #id-2005 > div:last-of-type .bubble-info {
    top: 40px;
    left: 10px;
  }

  #id-2006 > div:last-of-type .bubble-info {
    top: 100px;
    left: -5px;
  }

  #id-2008 > div:last-of-type .bubble-info {
    top: 0;
    left: 100px;
  }

  #id-2005 > div:first-of-type .timeline-bubbles-wrapper,
  #id-2006 > div:first-of-type .timeline-bubbles-wrapper,
  #id-2008 > div:first-of-type .timeline-bubbles-wrapper {
    margin-top: 0;
    justify-content: flex-end;
  }

  #id-2005 > div:last-of-type .timeline-bubbles-wrapper,
  #id-2006 > div:last-of-type .timeline-bubbles-wrapper,
  #id-2008 > div:last-of-type .timeline-bubbles-wrapper {
    margin-top: 200px;
  }

  #id-2008 > div:nth-of-type(2) .bubble-info {
    right: -180px;
    left: unset;
    top: 70px;
  }

  .third-container-2016 {
    flex-direction: row-reverse;
  }
  .third-container-2016 img {
    margin-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1001px) and (max-width: 1240px) {
  #id-2003 > div:first-of-type .bubble-info {
    right: 100px !important;
  }

  #id-2003 > div:nth-of-type(3) .bubble-info {
    left: -70px;
  }

  .bubbles-container-first__2003 > div:first-of-type .bubble-info {
    left: -60px !important;
  }

  .bubbles-container-first__2003
    > div:last-of-type
    img:not(.close-icon):not(.pointer),
  #id-2005 > div:first-of-type img:not(.close-icon):not(.pointer),
  #id-2005 > div:last-of-type img:not(.close-icon):not(.pointer),
  #id-2006 > div:first-of-type img:not(.close-icon):not(.pointer),
  #id-2006 > div:last-of-type img:not(.close-icon):not(.pointer),
  #id-2008 > div:first-of-type img:not(.close-icon):not(.pointer),
  #id-2008 > div:last-of-type img:not(.close-icon):not(.pointer) {
    height: 250px !important;
    width: 250px !important;
  }

  #id-2005 > div:nth-of-type(2) img:not(.close-icon):not(.pointer),
  #id-2006 > div:nth-of-type(2) img:not(.close-icon):not(.pointer),
  #id-2008 > div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
    height: 350px !important;
    width: 350px !important;
  }

  .bubbles-container-first__2003 > div:last-of-type .bubble-info {
    right: 0;
    left: -150px;
  }

  .bubbles-container-first__2003 > div:last-of-type .bubble-info a {
    left: 160px !important;
  }

  #id-2005 > div:last-of-type,
  #id-2006 > div:last-of-type,
  #id-2008 > div:last-of-type {
    margin-bottom: 0 !important;
  }

  #id-2005 > div:nth-of-type(2) .bubble-info > div,
  #id-2006 > div:nth-of-type(2) .bubble-info > div,
  #id-2008 > div:nth-of-type(2) .bubble-info > div {
    margin-right: 20px;
  }

  #id-2008 > div:last-of-type .bubble-info {
    top: -30px;
  }

  #id-2008 > div:nth-of-type(2) > .timeline-bubbles-wrapper .bubble-info a {
    order: 0;
    left: -50px;
  }

  #id-2010 .bubble-2010-2 .bubble-info > div {
    max-width: 100px;
  }

  #id-2010 .bubble-2010-2 .bubble-info a {
    left: unset;
    right: -80px;
  }

  #id-2010 .bubble-2010-2 .bubble-info {
    left: -105px;
    top: 0;
  }

  .bubble-2011
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > a {
    left: 0 !important;
    top: unset !important;
    bottom: -50px;
  }

  .bubble-2011 > div:last-of-type > .timeline-bubbles-wrapper .bubble-info > a {
    left: -30px !important;
    top: unset !important;
    bottom: -50px;
  }

  #id-2013 > div:first-of-type .timeline-bubbles-container .bubble-info {
    left: 200px;
  }

  #id-2013 > div:nth-of-type(2) .timeline-bubbles-container > img {
    height: 300px;
    width: 300px;
  }

  #id-2013 > div:nth-of-type(2) .timeline-bubbles-container .bubble-info {
    right: -125px;
  }

  #id-2013 > div:nth-of-type(2) .timeline-bubbles-container .bubble-info > a {
    left: -40px;
  }

  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img),
  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    .timeline-bubbles-container {
    height: 212px;
    width: 212px;
  }

  .bubbles-container-second__2017:first-of-type .timeline-bubbles-wrapper {
    align-items: flex-start;
  }

  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    bottom: unset;
    top: 0;
    right: -60px;
  }

  #id-2017
    .inner-timeline-content:first-of-type
    > div:last-of-type
    img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
    height: 300px;
    width: 300px;
  }

  #id-2017
    .inner-timeline-content:first-of-type
    > div:last-of-type
    .bubble-info
    a {
    top: 80px;
    left: -50px;
  }
}

@media (min-width: 1241px) {
  #id-2003 > div:nth-of-type(3) .timeline-grey-modal {
    top: -100px;
    left: unset;
    right: 340px;
    padding: 15px;
  }

  #id-2003 > div:nth-of-type(3) .timeline-grey-modal p,
  .bubbles-container-first__2003 > div:first-of-type .timeline-grey-modal p {
    font-size: 16px !important;
  }

  #id-2003 > div:nth-of-type(3) .timeline-grey-modal .pointer {
    right: -150px;
  }

  .bubbles-container-first__2003 > div:first-of-type .timeline-grey-modal {
    top: -40px !important;
    left: -620px !important;
  }

  .bubbles-container-first__2003
    > div:first-of-type
    .timeline-grey-modal
    .pointer {
    right: -180px;
    height: 100% !important;
  }

  #id-2005,
  #id-2006,
  #id-2008 {
    height: 85vh;
  }

  #id-2010 {
    width: 100% !important;
  }

  /* #id-2010 .bubble-2010-2 {
    margin-top: 130px;
  } */

  .story10Img img {
    height: 100%;
  }

  #id-2005 > div:first-of-type img:not(.close-icon):not(.pointer) {
    height: 230px !important;
    width: 230px !important;
  }

  #id-2006 > div:first-of-type img:not(.close-icon):not(.pointer) {
    height: 340px !important;
    width: 340px !important;
  }

  #id-2008 > div:first-of-type img:not(.close-icon):not(.pointer) {
    height: 230px !important;
    width: 230px !important;
  }

  #id-2005 > div:last-of-type img:not(.close-icon):not(.pointer) {
    height: 260px !important;
    width: 260px !important;
  }

  #id-2006 > div:last-of-type img:not(.close-icon):not(.pointer),
  #id-2008 > div:last-of-type img:not(.close-icon):not(.pointer) {
    height: 370px !important;
    width: 370px !important;
  }

  #id-2005 > div:nth-of-type(2) img:not(.close-icon):not(.pointer),
  #id-2006 > div:nth-of-type(2) img:not(.close-icon):not(.pointer),
  #id-2008 > div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
    height: 430px !important;
    width: 430px !important;
  }

  #id-2005 > div:first-of-type .bubble-info {
    top: 10px;
  }

  #id-2006 > div:first-of-type .bubble-info {
    top: 70px;
    right: 0;
  }

  #id-2008 > div:first-of-type .bubble-info {
    top: 45%;
    right: -15px;
  }

  #id-2005 > div:first-of-type .timeline-grey-modal,
  #id-2006 > div:first-of-type .timeline-grey-modal {
    width: 600px;
    left: 350px;
    bottom: -15px;
  }

  #id-2008 > div:first-of-type .timeline-grey-modal {
    width: 600px;
    bottom: -70px;
  }

  #id-2006 > div:first-of-type .timeline-grey-modal {
    left: 510px;
  }

  #id-2008 > div:first-of-type .timeline-grey-modal {
    left: 370px;
  }

  #id-2005 > div:first-of-type .timeline-grey-modal .pointer,
  #id-2006 > div:first-of-type .timeline-grey-modal .pointer,
  #id-2008 > div:first-of-type .timeline-grey-modal .pointer {
    transform: scaleX(-1);
    right: unset;
    left: -144px;
  }

  #id-2006 > div:first-of-type .timeline-grey-modal .pointer {
    left: -170px;
  }

  #id-2008 > div:first-of-type .timeline-grey-modal .pointer {
    left: -122px;
  }

  #id-2005 > div:last-of-type .timeline-grey-modal {
    left: -520px;
    bottom: 10px;
  }

  #id-2006 > div:last-of-type .timeline-grey-modal {
    left: -540px;
    bottom: 40px;
  }

  #id-2008 > div:last-of-type .timeline-grey-modal {
    width: 700px;
    top: -120px;
    left: -770px;
  }

  #id-2005 > div:last-of-type .timeline-grey-modal .pointer,
  #id-2006 > div:last-of-type .timeline-grey-modal .pointer {
    right: -125px;
  }

  #id-2008 > div:last-of-type .timeline-grey-modal .pointer {
    right: -165px;
  }

  #id-2010 .bubble-2010 .bubble-info > div {
    max-width: 250px;
  }

  #id-2010 .bubble-2010-2 .bubble-info {
    width: 100%;
    justify-content: flex-end;
  }

  #id-2010 .bubble-2010-2 .bubble-info > div {
    max-width: 150px;
  }

  .story9-lastp {
    padding-right: 0 !important;
    padding-left: 200px !important;
  }

  .story9-last-quote {
    margin-bottom: 70px;
    margin-top: 100px;
  }

  #id-2010
    .bubble-2010-2
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    transform: unset;
    right: 400px;
    top: 25%;
  }

  .slick-slide[data-index="9"] > div {
    height: 95%;
  }
}

@media (min-width: 1441px) {
  #id-2003 > div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
    height: 350px;
    width: 350px;
  }

  .bubbles-container-first__2003
    > div:last-of-type
    img:not(.close-icon):not(.pointer) {
    height: 350px !important;
    margin-bottom: 100px !important;
    width: 350px !important;
  }

  #id-2003 > div:nth-of-type(2) .bubble-info {
    left: -140px;
  }

  .bubbles-container-first__2003 > div:last-of-type .bubble-info {
    right: -140px;
  }

  #id-2003 > div:nth-of-type(3) .timeline-grey-modal {
    left: 190px;
    right: unset;
  }

  #id-2003 > div:nth-of-type(3) .timeline-grey-modal .pointer {
    transform: scaleX(-1);
    right: unset;
    left: -160px;
  }

  #id-2006 > div:nth-of-type(2) .bubble-info > div,
  #id-2008 > div:nth-of-type(2) .bubble-info > div {
    margin-right: 15px;
  }

  #id-2006 > div:nth-of-type(2) .bubble-info {
    left: -180px;
  }

  #id-2010
    .inner-timeline-content:first-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img),
  #id-2010
    .inner-timeline-content:last-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 416px;
    width: 416px;
  }
}

@media (min-width: 1461px) {
  #id-2003 > div:nth-of-type(2) .bubble-info {
    left: -190px;
  }

  .bubbles-container-first__2003 > div:last-of-type .bubble-info {
    right: -190px;
  }

  #id-2016 > div:nth-of-type(2) .bubble-info {
    left: -265px;
  }

  #id-2016 > div:nth-of-type(2) .bubble-info {
    flex-direction: row-reverse;
    left: -280px !important;
    top: 30px;
  }

  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info {
    left: -250px !important;
  }
  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info
    > div {
    max-width: 175px !important;
  }
  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info
    > div
    h2 {
    font-size: 24px;
  }
  #id-2017
    .bubble-2017
    > div:nth-of-type(2)
    div.timeline-bubbles-wrapper
    .timeline-bubbles-container
    img {
    height: 300px !important;
    width: 300px !important;
  }
}

@media (min-width: 1001px) and (max-width: 1440px) {
  .two-images div:first-of-type {
    height: 300px;
    width: 350px;
  }

  .two-images div:last-of-type {
    height: 350px;
    width: calc(100% - 390px);
  }

  .story4-float-img > div {
    margin-bottom: 50px;
  }

  .bubbles-container-first__2003 > div:last-of-type .bubble-info {
    right: -140px;
  }

  #id-2005 > div:nth-of-type(2) .bubble-info {
    left: -140px;
  }

  #id-2006 > div:nth-of-type(2) .bubble-info {
    left: -145px;
  }

  .two-images div:last-of-type,
  .two-images div:first-of-type {
    height: 100% !important;
    width: 100% !important;
  }

  .two-images div:first-of-type img {
    height: auto !important;
    width: 100% !important;
  }

  .two-images div:last-of-type img {
    height: auto !important;
    width: 100% !important;
  }

  #id-2012
    .bubble-2012
    > div:nth-of-type(1)
    img:not(.close-icon):not(.pointer) {
    height: 300px;
    width: 300px;
  }

  #id-2012 .bubble-2012,
  #id-2012 .bubble-2011 {
    margin-top: 0 !important;
  }

  #id-2012 .bubble-2011 > div:first-of-type .timeline-bubbles-wrapper {
    height: auto;
  }

  #id-2012 .bubble-2011 > div:last-of-type > div:last-of-type .pointer {
    left: -155px !important;
  }
}

@media (min-width: 1600px) {
  .two-images-fourth-modal div:last-of-type {
    height: 350px !important;
    width: 500px !important;
  }

  .story10video-wrapper {
    margin-bottom: 200px;
  }
}

@media (max-width: 1599px) {
  .story10video-wrapper {
    margin-bottom: 50px;
  }
}

@media (max-height: 900px) and (min-width: 1241px) {
  .inner-timeline-content:first-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 300px;
    width: 300px;
  }

  .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    right: -130px;
  }

  .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    > p {
    bottom: 55px;
  }

  #id-2001 > .inner-timeline-content:first-of-type {
    margin-top: 0;
    position: relative;
    justify-content: flex-start;
  }

  #id-2001 > .inner-timeline-content:first-of-type > div:last-of-type {
    position: absolute;
    right: 0;
    bottom: 100px;
  }

  .inner-timeline-content:first-of-type
    > div:last-of-type
    .timeline-grey-modal {
    bottom: -10px !important;
  }

  .inner-timeline-content:first-of-type
    > div:last-of-type
    img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
    margin-top: 0;
  }

  .bubbles-item-second__2001 {
    margin-top: 0px !important;
  }

  #id-2006 > div:first-of-type img:not(.close-icon):not(.pointer) {
    height: 250px !important;
    width: 250px !important;
  }

  #id-2008 > div:first-of-type img:not(.close-icon):not(.pointer) {
    height: 230px !important;
    width: 230px !important;
  }

  #id-2006 > div:first-of-type .bubble-info {
    top: 0;
    right: 35px;
  }

  #id-2008 > div:first-of-type .bubble-info {
    top: 45%;
    right: -15px;
  }

  #id-2006 > div:first-of-type .timeline-grey-modal,
  #id-2008 > div:first-of-type .timeline-grey-modal {
    bottom: -30px;
    left: 390px;
  }

  #id-2006 > div:nth-of-type(2) img:not(.close-icon):not(.pointer),
  #id-2008 > div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
    height: 380px !important;
    width: 380px !important;
  }

  #id-2006 > div:nth-of-type(2) .bubble-info {
    left: -115px;
    top: 0;
  }
}

.logo-completed {
  width: 265px;
}

.logo-completed-mobile {
  left: 15px;
  position: absolute;
  top: 45% !important;
  transform: translateY(-50%);
  width: 100px !important;
}

.bubbles-container-second__2018 > div:first-of-type {
  margin-top: 50px;
}

.bubbles-container-second__2018
  > div:first-of-type
  img:not(.close-icon):not(.pointer) {
  height: 165px !important;
  width: 165px !important;
}

.bubbles-container-second__2018
  > div:first-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  left: 0 !important;
  top: 50px !important;
}

.bubbles-container-second__2018 > div:first-of-type .pointer {
  height: 100% !important;
  right: -150px;
}

.bubbles-container-second__2018 > div:first-of-type .timeline-grey-modal {
  top: -70px !important;
  left: -160px !important;
}

.bubbles-container-second__2018 > div:last-of-type {
  justify-content: center !important;
}

.bubbles-container-second__2018 > div:last-of-type img {
  height: 420px !important;
  width: 420px !important;
}

.bubbles-container-second__2018
  > div:last-of-type
  > .timeline-bubbles-wrapper
  .bubble-info {
  right: -330px;
}

.bubbles-container-second__2018
  > div:last-of-type
  > .timeline-bubbles-wrapper
  .bubble-info
  > div {
  max-width: 160px;
  margin: 0 15px;
}

.story17-third-modal-hero {
  height: 365px;
  padding: 0;
  margin-left: 150px;
  object-fit: cover;
  width: 725px;
}

.story17-first-p {
  padding-right: 0 !important;
}

.story17-first-img,
.story17-quote {
  margin: 80px 0;
}

.story17-first-img .caption {
  max-width: 880px;
}

.story17-text-img-wrapper img {
  height: 500px !important;
  width: 420px !important;
}

.story17two-images {
  padding-left: 0;
  margin: 80px 0 !important;
  margin-bottom: 130px !important;
}

.story17two-images > div {
  height: 440px !important;
  width: calc(50% - 20px) !important;
}

.story17two-images:last-of-type > div {
  height: 320px !important;
  width: calc(50% - 20px) !important;
}

.story17two-images > div img {
  object-fit: cover;
}

.story17-modal-calloutbox {
  font-size: 18px;
  line-height: 30px;
}

.story18-first-img {
  height: 400px !important;
  margin-bottom: 150px;
  width: 730px !important;
}

.story18-first-img img,
.story18-second-img img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.story18-quote {
  margin: 80px 0;
}

.story18-second-img {
  height: 500px;
  margin-bottom: 130px;
  margin-top: 80px;
  padding-left: 300px;
  width: 730px;
}

.story-big-img {
  width: auto !important;
}

.story-img-container {
  margin-bottom: 50px !important;
}

@media screen and (min-width: 1001px) {
  #id-2009 {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  #id-2009 div:first-of-type {
    margin-left: 36px;
  }

  #id-2009 div:first-of-type .bubble-info {
    flex-direction: row-reverse;
    left: -250px;
    top: 0px;
  }

  #id-2009 div:first-of-type img {
    max-width: 430px;
    width: 100%;
  }

  #id-2009 div:nth-of-type(2) img {
    /* max-width: 430px; */
    width: 60%;
  }

  #id-2009 div:first-of-type .bubble-info > div {
    max-width: 170px;
    margin-right: 0;
  }

  #id-2009 div:nth-of-type(2) .bubble-info {
    right: 180px;
    top: 30px;
    margin: 0;
  }

  #id-2009 div:nth-of-type(2) {
    align-self: flex-end;
    margin-top: 320px;
  }

  #id-2009 div:nth-of-type(2) .timeline-bubbles-wrapper {
    position: relative;
  }
  #id-2009 div:nth-of-type(2) .timeline-grey-modal {
    height: auto;
    margin-top: 0;
    top: -90px;
    right: -600px;
    width: 621px;
  }
  #id-2009 div:nth-of-type(2) .timeline-grey-modal .modal-content {
    margin-left: 0;
  }
  #id-2009 div:nth-of-type(2) .timeline-grey-modal img {
    width: auto;
  }
  #id-2009 div:nth-of-type(2) .timeline-grey-modal .pointer {
    left: -155px;
    transform: scaleX(-1);
  }
  #id-2009 div:nth-of-type(2) .timeline-bubbles-wrapper a {
    position: relative;
  }
  #id-2016 {
    justify-content: space-evenly;
  }
  #id-2016 > div:first-of-type .timeline-bubbles-container {
    /* margin-top: 220px; */
    margin-top: 150px;
  }
  #id-2016 > div:first-of-type .timeline-grey-modal {
    left: 160%;
    top: 0;
    width: 500px;
  }
  #id-2016 > div:first-of-type .timeline-bubbles-wrapper {
    position: relative;
  }
  #id-2016 > div:first-of-type .timeline-bubbles-wrapper .pointer {
    left: -132px;
    transform: scale(-1, 1);
  }
  #id-2016 > div:first-of-type .bubble-info {
    bottom: 50%;
    right: -10px;
  }
  #id-2016 > div:nth-of-type(2) .timeline-bubbles-wrapper {
    /* margin-top: 450px; */
    margin-top: 350px;
  }
  #id-2016 > div:nth-of-type(2) .bubble-info {
    flex-direction: row-reverse;
    left: -220px;
    top: 30px;
  }
  #id-2016 > div:nth-of-type(2) .bubble-info a {
    left: unset !important;
    right: -50px;
    top: 100px;
  }
  #id-2016 > div:nth-of-type(2) .bubble-info > div {
    max-width: 220px;
    text-align: right;
  }
  #id-2016 > div:nth-of-type(2) .bubble-info > div a,
  #id-2016 > div:nth-of-type(2) .bubble-info > div h2 {
    margin-right: 20px;
  }
  #id-2016 > div:first-of-type img:not(.close-icon):not(.pointer) {
    height: 219px;
    width: 219px;
  }
  #id-2016 > div:nth-of-type(2) img:not(.close-icon):not(.pointer) {
    height: 340px;
    width: 340px;
  }
  #id-2016 > div:nth-of-type(3) img:not(.close-icon):not(.pointer) {
    height: 250px;
    width: 250px;
  }
  #id-2016 > div:nth-of-type(3) .timeline-bubbles-wrapper {
    margin-left: 50px;
    margin-top: 450px;
    position: relative;
  }
  #id-2016 > div:nth-of-type(3) .timeline-bubbles-wrapper .bubble-info {
    left: 10px;
    top: 40px;
  }

  #id-2016 > div:nth-of-type(3) .timeline-grey-modal {
    min-width: 550px;
    right: 450px;
    top: -100px;
  }

  #id-2016 > div:nth-of-type(3) .timeline-grey-modal .pointer {
    right: -175px;
  }

  #id-2017 .bubble-2017-2 > div:nth-of-type(2) .timeline-bubbles-container img {
    width: 300px;
  }

  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info
    a {
    left: 10px;
  }

  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info {
    left: -130px;
    top: 0px;
  }

  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info
    a {
    left: unset;
    right: -50px;
    top: 100px;
  }

  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info
    h2 {
    font-size: 16px;
  }

  #id-2017 .bubble-2017-2 > div:nth-of-type(3) .timeline-bubbles-container img {
    height: 212px;
    width: 212px;
  }

  #id-2017 .bubble-2017-2 > div:nth-of-type(3) {
    align-items: flex-end;
  }

  #id-2018 .bubbles-container-first__2018 > div:nth-of-type(1) {
    margin-left: 50px;
    margin-top: 100px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(1)
    .timeline-bubbles-container
    > img {
    height: 429px;
    max-height: 429px;
    max-width: 428px;
    width: 428px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(1)
    .timeline-bubbles-container
    .bubble-info {
    right: -112%;
    top: 180px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(1)
    .timeline-bubbles-container
    .bubble-info
    > div {
    margin-left: 25px;
    max-width: 210px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(1)
    .timeline-bubbles-container
    .bubble-info
    > div
    h2 {
    font-size: 24px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(1)
    .timeline-bubbles-container
    .bubble-info
    > div
    a {
    font-size: 70px;
  }

  #id-2018 .bubbles-container-first__2018 > div:nth-of-type(2) {
    margin-top: 0px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(1)
    .timeline-bubbles-container
    .bubble-info {
    right: -112%;
    top: 180px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper {
    margin-right: 80px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(2)
    .timeline-bubbles-container
    > img {
    height: 276px;
    max-height: 276px;
    max-width: 276px;
    width: 276px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(2)
    .timeline-grey-modal {
    bottom: -80px !important;
    left: unset;
    right: -800px;
    width: 600px !important;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(2)
    .timeline-grey-modal
    .pointer {
    left: -175px !important;
    transform: scale(-1, 1);
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(2)
    .timeline-grey-modal
    .modal-content
    p {
    font-size: 18px !important;
  }

  .story16third-third-container .caption {
    max-width: 480px;
  }

  #id-2021 {
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  #id-2021 .inner-timeline-content {
    margin-right: 0;
    margin-top: 100px;
  }

  #id-2021 .inner-timeline-content > div {
    justify-content: center;
  }

  #id-2021 .inner-timeline-content .timeline-bubbles-wrapper {
    height: 420px;
    width: 420px;
  }

  #id-2021 .inner-timeline-content .timeline-bubbles-wrapper h2 {
    font-size: 24px;
    text-align: right;
  }

  #id-2021 .timeline-bubbles-container > img {
    height: 420px;
    width: 420px;
  }

  #id-2021 .bubble-info {
    flex-direction: row-reverse;
    left: 50% !important;
    top: 0 !important;
  }

  #id-2021 .bubble-info a {
    margin-top: 80px;
  }
}

@media screen and (max-width: 1240px) {
  #id-2009 div:first-of-type .bubble-info {
    left: -200px;
    top: 40px;
  }
  #id-2009 div:first-of-type .bubble-info a {
    left: unset;
    right: -60px;
  }
  #id-2009 div:nth-of-type(2) {
    margin-bottom: 60px;
    margin-top: 0;
  }
  #id-2009 div:nth-of-type(2) .bubble-info {
    left: 0;
    top: 0;
  }
  #id-2009 div:nth-of-type(2) .bubble-info a {
    left: unset;
    right: -40px;
  }

  .story11-third-2-img {
    padding-left: 0;
    width: 100%;
  }

  .story11-third-2-img-caption {
    max-width: 100% !important;
    padding: 0 !important;
  }

  .story11two-images {
    padding-left: 0 !important;
  }

  .story11-third-second-container {
    padding-left: 0;
  }

  .story11-third-second-container img {
    height: auto !important;
    width: 100%;
  }

  .story11-third-second-container .caption {
    width: 100%;
  }
  #id-2005 img,
  #id-2006 img,
  #id-2008 img,
  #id-2016 img {
    height: 280px;
    width: 280px;
  }
  #id-2005 div:nth-of-type(2) .bubble-info a,
  #id-2006 div:nth-of-type(2) .bubble-info a,
  #id-2008 div:nth-of-type(2) .bubble-info a,
  #id-2016 div:nth-of-type(2) .bubble-info a {
    left: 15%;
  }
}
@media screen and (max-width: 1000px) {
  #id-2009 div:first-of-type .timeline-bubbles-container {
    align-items: center;
  }
  #id-2009 div:first-of-type .timeline-bubbles-container img {
    max-width: 250px;
    width: 100%;
  }
  #id-2009 div:first-of-type .bubble-info a {
    right: -100px;
  }

  #id-2010 .bubble-2010-2 div:nth-of-type(2) {
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0;
  }

  .bubble-2011
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > a {
    left: 50px !important;
    top: 50px !important;
  }

  .bubble-2011 > div:last-of-type > .timeline-bubbles-wrapper .bubble-info > a {
    left: 50px !important;
    top: 30px !important;
  }

  #id-2012
    .bubble-2012
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    left: 0 !important;
  }

  #id-2013 {
    height: auto;
    padding-left: unset;
  }

  #id-2013 > div:first-of-type,
  #id-2013 > div:last-of-type,
  #id-2013 > div:nth-of-type(2) {
    width: unset;
  }

  #id-2013 > div:nth-of-type(2) {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  #id-2013 > div:nth-of-type(2) .timeline-bubbles-container > img {
    height: 300px;
    width: 300px;
  }

  #id-2013 > div:nth-of-type(2) .timeline-bubbles-container .bubble-info > a {
    left: 60px;
  }

  .story11video {
    padding-left: 0;
  }

  .story11video iframe {
    height: 250px;
    width: 100%;
  }

  .story11calloutbox {
    margin-right: 0;
  }

  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info
    a {
    left: 10px;
  }
  #id-2017 .bubble-2017-2 > div:nth-of-type(3) .timeline-bubbles-wrapper {
    width: 100%;
  }
  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(2)
    .timeline-bubbles-container
    > img {
    height: 240px;
    width: 240px;
  }

  .bubbles-container-second__2018 > div:first-of-type {
    margin-top: 0;
  }

  .bubbles-container-second__2018
    > div:first-of-type
    img:not(.close-icon):not(.pointer),
  .bubbles-container-second__2018 > div:last-of-type img,
  #id-2020 img:not(.close-icon):not(.pointer) {
    height: 240px !important;
    width: 240px !important;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    left: 80px !important;
    top: -30px !important;
  }

  .bubbles-container-second__2020 .timeline-bubbles-wrapper {
    margin: 0 auto;
  }

  #id-2020
    .bubbles-container-second__2020
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: 60px !important;
  }

  .story18-first-img {
    height: auto !important;
    margin-bottom: 50px;
    width: 100% !important;
  }

  .story18-second-img {
    height: auto;
    margin-bottom: 50px;
    margin-top: 50px;
    padding-left: 0;
    width: 100%;
  }

  .story18-first-img img {
    object-fit: contain;
  }

  .bubbles-container-second__2018
    > div:first-of-type
    > .timeline-bubbles-wrapper {
    margin-bottom: 0 !important;
  }

  .bubbles-container-second__2018 .timeline-bubbles-wrapper:last-of-type {
    width: 100% !important;
  }

  .bubbles-container-second__2018
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    a {
    left: 0;
  }

  .story17-third-modal-hero {
    height: auto;
    margin-left: 0;
    width: 100%;
  }

  .story17-first-p {
    margin-top: 50px;
  }

  .story17-first-img img {
    width: 100%;
  }

  .story17-first-img .caption {
    max-width: 100%;
  }

  .story17-text-img-wrapper img {
    height: auto !important;
    width: 100% !important;
  }

  .story17two-images {
    margin-bottom: 50px !important;
  }

  .story17two-images > div {
    height: auto !important;
    width: 100% !important;
  }

  .story17two-images:last-of-type > div {
    height: auto !important;
    width: 100% !important;
  }

  #id-2020 .bubbles-container-second__2020 > div:nth-of-type(2) .bubble-info a {
    top: -20px;
  }

  #id-2021 .timeline-bubbles-wrapper .timeline-bubbles-container .bubble-info {
    position: relative !important;
  }
}

.slick-dots {
  z-index: 0 !important;
}

#id-2009 img {
  width: 60%;
}

.container-animation-mobile_completed {
  background-color: #fff;
  display: block;
  /* left: 10px; */
  left: 0;
  height: 100px;
  position: fixed !important;
  /* top: 17px !important; */
  /* top: 50px !important; */
  top: 0px !important;
  transition: all 1s ease-in-out;
  -webkit-backface-visibility: hidden;
  width: 100%;
  z-index: 999;
}

.container-animation-mobile_completed img {
  transition: all 0.1 ease-out;
}

.animation-anniversary img {
  transition: all 1s ease-in-out;
}

@media (min-width: 1241px) and (max-height: 750px) {
  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img),
  .bubbles-container-second__2017:first-of-type
    > div:first-of-type
    .timeline-bubbles-container {
    height: 212px;
    width: 212px;
  }

  .bubbles-container-second__2017:first-of-type .timeline-bubbles-wrapper {
    align-items: flex-start;
  }

  .inner-timeline-content:first-of-type
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    right: -30px;
  }

  #id-2017
    .inner-timeline-content:first-of-type
    > div:first-of-type
    .timeline-grey-modal {
    top: 0px;
    left: 450px;
  }

  .bubble-2017-2:last-of-type > div:last-of-type {
    height: auto;
  }

  .bubble-2017 > div {
    height: 200px;
  }

  #id-2017
    .inner-timeline-content:first-of-type
    > div:last-of-type
    img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
    height: 350px;
    width: 350px;
  }

  .bubbles-container-second__2017:first-of-type
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    right: -270px;
  }
}

@media screen and (min-width: 1241px) {
  #id-2017
    .bubble-2017
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .timeline-bubbles-container
    img {
    height: 350px !important;
    width: 350px !important;
  }
  #id-2017
    .bubble-2017
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .timeline-bubbles-container
    .bubble-info {
    right: -270px;
  }
  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info {
    flex-direction: row-reverse;
    left: -210px;
  }
  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info
    h2 {
    font-size: 20px;
  }
  #id-2017
    .bubble-2017-2
    > div:nth-of-type(2)
    .timeline-bubbles-wrapper
    .bubble-info
    > div {
    max-width: 200px;
  }

  .bubbles-container-first__2020
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 300px !important;
    width: 300px !important;
  }

  .bubbles-container-first__2020
    > div:nth-of-type(2)
    img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
    height: 185px !important;
    width: 185px !important;
  }

  .bubbles-container-second__2020
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img):not(.pointer) {
    height: 300px !important;
    width: 300px !important;
  }

  .bubbles-container-second__2020 > div:first-of-type .pointer {
    height: 100% !important;
    transform: unset !important;
    left: 550px !important;
  }

  .bubbles-container-second__2020 > div:first-of-type .timeline-grey-modal {
    bottom: 15px !important;
    top: unset !important;
    left: unset !important;
    right: 370px;
    width: 550px;
  }

  #id-2020 .bubbles-container-first__2020 > div:last-of-type .pointer {
    transform: scaleX(-1);
    left: -100px !important;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: 20px;
    right: 0;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:last-of-type
    .timeline-grey-modal {
    right: -580px;
    bottom: 25px !important;
  }

  .bubbles-container-second__2020
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    left: 10px !important;
    top: 70px !important;
  }
}

@media (min-width: 1241px) and (max-height: 750px) {
  .bubbles-container-first__2020
    > div:first-of-type
    img:not(.close-icon):not(.img-copy-modal img) {
    height: 350px !important;
    width: 350px !important;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    right: -210px;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:first-of-type
    > .timeline-bubbles-wrapper
    .bubble-info
    > a {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1450px) {
  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(1)
    .timeline-bubbles-container
    .bubble-info {
    right: -430px;
  }

  .modal-story-p.story17two-images > div {
    height: auto !important;
  }

  #id-2020
    .bubbles-container-second__2020
    > div:first-of-type
    .timeline-bubbles-container
    .bubble-info {
    left: -110px !important;
  }

  #id-2020
    .bubbles-container-second__2020
    > div:first-of-type
    .timeline-bubbles-container
    .bubble-info
    div {
    max-width: 225px;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:first-of-type
    .timeline-bubbles-container
    .bubble-info
    div {
    max-width: 225px;
  }

  #id-2020
    .bubbles-container-first__2020
    > div:first-of-type
    .timeline-bubbles-container
    .bubble-info {
    right: -300px !important;
  }

  #id-2020 .bubbles-container-second__2020 > div:first-of-type {
    margin-top: 150px;
  }
}

@media (min-width: 1241px) and (max-height: 1000px) {
  #id-2018 .bubbles-container-first__2018 > div:nth-of-type(1) {
    margin-top: 0px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(1)
    .timeline-bubbles-container
    img {
    height: 350px;
    width: 350px;
  }

  #id-2018
    .bubbles-container-first__2018
    > div:nth-of-type(1)
    .timeline-bubbles-container
    .bubble-info {
    right: -335px;
  }

  #id-2018 .bubbles-container-first__2018 > div:nth-of-type(2) {
    margin-top: -30px;
  }

  #id-2021 .timeline-bubbles-wrapper .timeline-bubbles-container img {
    height: 360px;
    margin-top: 100px;
    width: 360px;
  }
}

@media (min-width: 1650px) {
  .story17two-images:last-of-type > div {
    height: auto !important;
  }

  .story17two-images > div img {
    height: auto !important;
    object-fit: contain;
  }

  .pagination-ul li {
    font-size: 20px;
  }
}

@media (min-height: 900px) {
  #id-2020
    .bubbles-container-first__2020
    > div:last-of-type
    > .timeline-bubbles-wrapper
    .bubble-info {
    top: unset;
    bottom: 140px;
    right: 0;
  }

  #id-2020 .bubbles-container-first__2020 > div:last-of-type .pointer {
    left: -110px !important;
  }
}

@media (min-width: 1001px) {
  #id-2020 .bubbles-container-second__2020 > div:first-of-type img {
    height: 350px !important;
    width: 350px !important;
  }

  #id-2020 .bubbles-container-second__2020 > div:first-of-type .bubble-info {
    left: -70px !important;
    top: 80px !important;
  }

  #id-2020
    .bubbles-container-second__2020
    > div:first-of-type
    .bubble-info
    div {
    margin-right: 25px;
    max-width: 185px;
    text-align: right;
  }

  #id-2020 .bubbles-container-first__2020 > div:first-of-type .bubble-info {
    right: -250px !important;
  }

  #id-2020 .bubbles-container-first__2020 > div:first-of-type .bubble-info div {
    max-width: 200px;
  }

  #id-2020
    .bubbles-container-second__2020
    > div:nth-of-type(2)
    .timeline-grey-modal {
    left: unset;
    margin-bottom: -50px;
    right: 450px;
  }

  #id-2020
    .bubbles-container-second__2020
    > div:nth-of-type(2)
    .timeline-grey-modal
    .pointer {
    left: unset;
    right: -159px;
    transform: unset;
  }
}
